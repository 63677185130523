import Component from 'ds-framework/core/Component';
import template from './beneficio.html';
import usuarioStore from '../../store/usuarioStore';
import contratoStore from '../../store/contratoStore';
import Dom from 'ds-framework/core/Dom';
import { api } from 'ds-framework';

class Beneficio extends Component {
  constructor() {
    super(template);
    contratoStore.onStateChange(() => {
      this.mostrar();
      this.listaBeneficio();
    });
    usuarioStore.onStateChange(() => this.mostrar());
    this.addEvent('click', '#btn-novo', () => this.cancelar());
  }

  mostrar() {
    const empresa = usuarioStore.state.empresa;
    const contrato = contratoStore.state.contrato;

    if (empresa.autenticado === true && contrato.beneficiarioId) {
      this.$el.css('display', '');
    } else {
      this.$el.css('display', 'none');
    }
  }

  listaBeneficio() {
    const beneficios = usuarioStore.state.empresa.beneficios || [];
    this.$tbody.empty();
    beneficios.forEach(beneficio => {
      const $tr = new Dom(document.createElement('tr'));
      $tr.html(`
                <tr>
                    <td>${beneficio.descricao}</td>
                    <td class="text-center"><button class="btn btn-success btn-sm">Consultar</button></td>
                    <td class="text-center autorizado" > </td>
                    <td class="observacao" > </td>
                    <td class="text-center guia" ></td>
                </tr>
            `);
      const $btnConfirmar = $tr.find('.btn-success');

      const handlerClick = () => {
        const contrato = contratoStore.state.contrato;
        $btnConfirmar.html(`<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Consultar`).attr('disabled', true);
        api.post({
          url: `v1/portal-conveniado/validar-beneficio`,
          data: {
            beneficioId: beneficio.id,
            beneficiarioId: contrato.beneficiarioId
          }
        }).then(response => {
          $btnConfirmar.html(`Consultar`);
          $btnConfirmar.off('click', handlerClick);
          $tr.find('.autorizado').html(`<i class="fas ${response.autorizado == true ? `fa-thumbs-up text-success` : `fa-thumbs-down text-danger`}"></i>`);
          $tr.find('.observacao').html(response.observacao);

          if (response.autorizado) {
            const $btnGuia = new Dom(`<button class="btn btn-secondary btn-sm">Imprimir Guia <i class="fas fa-print"></i></button>`);
            $btnGuia.click(() => {
              $btnGuia.html(`<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Imprimir Guia`).attr('disabled', true);
              api.post({
                url: `v1/portal-conveniado/imprimir-guia/${response.guiaId}`
              }).then(response => {
                $btnGuia.html(`Imprimir Guia`).removeAttr('disabled');

                if (response.id) {
                  window.open(`${process.env.BASE_URL}/estatico/?name=${response.id}&mediaType=application/pdf`, '_blank');
                }
              }).catch(error => {
                $btnGuia.html(`</span> Imprimir Guia`).removeAttr('disabled');
              });
            });
            $tr.find('.guia').append($btnGuia);
          }
        });
      };

      $btnConfirmar.click(handlerClick);
      this.$tbody.append($tr);
    });
  }

  cancelar() {
    const empresa = usuarioStore.state.empresa;
    empresa.autenticado = empresa._Autenticado;
    contratoStore.commit({
      contrato: {},
      contratos: [],
      limparContrato: true,
      informacaoContrato: {}
    });
  }

  onRendered() {
    this.$tbody = this.$el.find('tbody');
    this.mostrar();
    this.listaBeneficio();
  }

}

export default Beneficio;
import Component from 'ds-framework/core/Component';
import template from './autenticar.html';
import IMask from 'imask';
import usuarioStore from '../../store/usuarioStore';
import contratoStore from '../../store/contratoStore';
import validationStore from '../../store/validationStore';
import Validation from '../Validation';

class Autenticar extends Component {
  constructor() {
    super(template);
    contratoStore.onStateChange(() => this.mostrar());
    usuarioStore.onStateChange(() => this.mostrar());
    this.components.push({
      name: 'validation',
      Component: Validation
    });
    this.addEvent('click', '#btn-confirmar', () => this.confirmar());
    this.addEvent('click', '#btn-cancelar', () => this.cancelar());
  }

  confirmar() {
    const empresa = usuarioStore.state.empresa;
    const contrato = contratoStore.state.contrato;
    const dataNascimento = this.maskDataNascimento.typedValue;
    validationStore.commit({
      erros: []
    });

    if (!dataNascimento) {
      validationStore.commit({
        erros: ['Digite a Data de nascimento do beneficiário']
      });
      return;
    }

    const data = {
      dataNascimento: dataNascimento.toISOString(),
      convenioId: empresa.id,
      beneficiarioId: contrato.beneficiarioId
    };
    this.$btnConfirmar.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Confirmar');
    usuarioStore.autenticarConvenio(data).then(() => this.$btnConfirmar.html('Confirmar')).catch(() => this.$btnConfirmar.html('Confirmar'));
  }

  cancelar() {
    contratoStore.commit({
      contrato: {},
      contratos: [],
      limparContrato: true
    });
  }

  mostrar() {
    const empresa = usuarioStore.state.empresa;
    const contrato = contratoStore.state.contrato;

    if (empresa.autenticado === false && contrato.beneficiarioId) {
      this.$el.css('display', '');
    } else {
      this.$el.css('display', 'none');
    }

    this.$dataNascimento.val('');
    this.maskDataNascimento.updateValue();
  }

  onRendered() {
    this.$btnConfirmar = this.$el.find('#btn-confirmar');
    this.$dataNascimento = this.$el.find('input[name="dataNascimento"]');
    this.maskDataNascimento = IMask(this.$dataNascimento[0], {
      mask: Date,
      lazy: false
    });
    this.mostrar();
  }

}

export default Autenticar;
import { api } from "ds-framework";
import Store from "ds-framework/core/Store";

class ContratoStore extends Store {
  constructor() {
    super(ContratoStore, {
      contratos: [],
      contrato: {},
      encontrado: true,
      carencias: [],
      limparContrato: false,
      informacaoContrato: {}
    });
  }

  getInformacoesContrato(id) {
    api.get({
      url: 'v1/portal-conveniado/informacao-contrato',
      data: {
        id: id
      }
    }).then(data => {
      this.commit({
        informacaoContrato: data
      });
    });
  }

}

const contratoStore = new ContratoStore();
export default contratoStore;
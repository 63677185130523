import Component from 'ds-framework/core/Component';
import validationStore from '../../store/validationStore';
import Dom from 'ds-framework/core/Dom';

class Validation extends Component {
  constructor() {
    super(`<div></div>`);
    validationStore.onStateChange(() => this.show());
  }

  show() {
    const erros = validationStore.state.erros;
    this.$el.empty('');
    erros.forEach(error => this.$el.append(new Dom(`<div class='alert alert-warning mt-4' role='alert'>${error}</div>`)));
  }

  onRendered() {
    this.show();
  }

}

export default Validation;
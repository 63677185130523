import api from "ds-framework/core/api";
import Store from "ds-framework/core/Store";
import validationStore from "./validationStore";

class UsuarioStore extends Store {
  constructor() {
    super(UsuarioStore, {
      usuario: {
        email: '',
        nome: '',
        token: ''
      },
      primeiroAcesso: false,
      empresas: [],
      empresa: {}
    });
  }

  setLocalToken(usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
  }

  localToken() {
    try {
      const usuario = JSON.parse(localStorage.getItem('usuario')) || {};
      this.commit({
        usuario: usuario
      });
    } catch (error) {}
  }

  async verifyToken() {
    const result = await api.post({
      url: `v1/auth/valid-token`,
      data: {
        token: `${usuarioStore.state.usuario.token}`
      }
    });

    if (!result.isValid) {
      this.setLocalToken({});
      this.commit({
        usuario: {}
      });
      return Promise.reject();
    }

    return Promise.resolve();
  }

  async verificarUsuario() {
    const usuario = this.state.usuario;

    if (usuario) {
      const result = await api.get({
        url: `v1/portal-conveniado/verificar-usuario`
      });
      this.commit({
        primeiroAcesso: result.primeiroAcesso,
        empresas: result.portalEmpresaConveniadas,
        empresa: result.portalEmpresaConveniadas[0]
      });
    }
  }

  async getTokenCorporacao() {
    const result = await api.post({
      url: 'v1/auth/login-corporacao',
      data: {
        chave: process.env.APP_CHAVE_CORPORACAO
      }
    });
    this.commit({
      usuario: {
        email: '',
        nome: '',
        token: result.token
      }
    });
  }

  async autenticarConvenio(payload) {
    const result = await api.post({
      url: `v1/portal-conveniado/autenticar-convenio`,
      data: payload
    });

    if (result.isValid) {
      const empresa = this.state.empresa;
      empresa.autenticado = true;
      this.commit({
        empresa: empresa
      });
    } else {
      validationStore.commit({
        erros: result.errors
      });
    }
  }

}

const usuarioStore = new UsuarioStore();
export default usuarioStore;
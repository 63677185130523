import Component from "ds-framework/core/Component";
import configuracaoStore from "../../store/configuracaoStore";
import template from './header.html';
import mobileStore from "../../store/mobileStore";
import router from "ds-framework/core/router";

class Header extends Component {
  constructor() {
    super(template);
    configuracaoStore.onStateChange(() => this.showLogo());
    mobileStore.onStateChange(() => this.showEmpresa());
    this.addEvent('click', '#btn-sair', () => this.sair());
    this.addEvent('click', '#btn-primeiro', () => router.navigate('primeiros-passos'));
  }

  showLogo() {
    const configuracao = configuracaoStore.state.configuracao;

    if (configuracao) {
      this.$imgLogo.attr('src', configuracao.linkLogoEmpresa);
      this.$descricaoEmpresa.html(configuracao.descricaoEmpresa || '');
    }
  }

  showEmpresa() {
    const isMobile = mobileStore.state.isMobile;
    this.$descricaoEmpresa.css('display', isMobile ? 'none' : '');
  }

  sair() {
    localStorage.setItem('usuario', '');
    window.location.reload();
  }

  onRendered() {
    this.$imgLogo = this.$el.find('#img-logo');
    this.$descricaoEmpresa = this.$el.find('#descricao-empresa');
    this.showLogo();
  }

}

export default Header;
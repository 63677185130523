import Component from 'ds-framework/core/Component';
import template from './login.html';
import formGetData from 'ds-framework/component/form/formGetData';
import api from 'ds-framework/core/api';
import validationStore from '../../store/validationStore';
import Dom from 'ds-framework/core/Dom';
import router from 'ds-framework/core/router';
import usuarioStore from '../../store/usuarioStore';
import configuracaoStore from '../../store/configuracaoStore';
import Validation from '../Validation';

class Login extends Component {
  constructor() {
    super(template);
    this.components.push({
      name: 'validation',
      Component: Validation
    });
    configuracaoStore.onStateChange(() => this.showLogo());
    this.addEvent('click', '#btn-logar', () => this.logar());
    this.addEvent('keyup', '#form-login-email', e => {
      if (e.keyCode == 13) {
        this.$inputSenha.focus();
      }
    });
    this.addEvent('keyup', '#form-login-senha', e => {
      if (e.keyCode == 13) {
        this.$btnLogar.focus();
      }
    });
  }

  async logar() {
    validationStore.commit({
      erros: []
    });
    this.$btnLogar.html(`<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Entrar <i class="fas fa-check"></i>`);
    this.$btnLogar.attr('disabled', 'true');
    const data = formGetData(this.$formLogin);
    data.chave = process.env.APP_CHAVE_CORPORACAO;

    try {
      const result = await api.post({
        url: 'v1/auth/login-usuario',
        data: data
      });

      if (!result.isValid) {
        validationStore.commit({
          erros: result.erros
        });
        this.$inputEmail[0].select();
        this.$inputEmail.focus();
      } else {
        const usuario = {
          token: result.token,
          nome: result.nome,
          email: data.email
        };
        usuarioStore.setLocalToken(usuario);
        usuarioStore.commit({
          usuario: usuario
        });
        router.navigate('verificar-usuario');
      }
    } catch (error) {}

    this.$btnLogar.removeAttr('disabled');
    this.$btnLogar.html(`Entrar <i class="fas fa-check"></i>`);
    return Promise.resolve();
  }

  showLogo() {
    const configuracao = configuracaoStore.state.configuracao;

    if (configuracao) {
      this.$imgEmpresa.attr('src', configuracao.linkLogoEmpresa);
    }
  }

  onRendered() {
    this.$imgEmpresa = this.$el.find('#img-empresa');
    this.$formLogin = this.$el.find('#form-login');
    this.$btnLogar = this.$el.find('#btn-logar');
    this.$inputEmail = this.$el.find('#form-login-email');
    this.$inputSenha = this.$el.find('#form-login-senha');
    this.$inputEmail.focus();
    this.showLogo();
  }

}

export default Login;
import Store from 'ds-framework/core/Store';

class ValidationStore extends Store {
  constructor() {
    super(ValidationStore, {
      erros: []
    });
  }

}

const validationStore = new ValidationStore();
export default validationStore;
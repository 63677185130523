import Component from 'ds-framework/core/Component';
import template from './troca-senha.html';
import formGetData from 'ds-framework/component/form/formGetData';
import api from 'ds-framework/core/api';
import validationStore from '../../store/validationStore';
import Dom from 'ds-framework/core/Dom';
import router from 'ds-framework/core/router';
import usuarioStore from '../../store/usuarioStore';
import { Modal } from 'bootstrap';

class TrocaSenha extends Component {
  constructor() {
    super(template);
    validationStore.onStateChange(() => this.showValidationErro());
    this.addEvent('click', '#btn-confirmar', () => this.confirmar());
  }

  async confirmar() {
    validationStore.commit({
      erros: []
    });
    this.$btnLogar.html(`<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Confirmar <i class="fas fa-check"></i>`);
    this.$btnLogar.attr('disabled', 'true');
    const data = formGetData(this.$formLogin);

    try {
      const result = await api.post({
        url: 'v1/portal-conveniado/trocar-senha',
        data: data
      });

      if (!result.isValid) {
        validationStore.commit({
          erros: result.errors
        });
      } else {
        this.modal.show();
      }
    } catch (error) {}

    this.$btnLogar.removeAttr('disabled');
    this.$btnLogar.html(`Confirmar <i class="fas fa-check"></i>`);
    return Promise.resolve();
  }

  showValidationErro() {
    this.$errorValidadation.empty();
    validationStore.state.erros.forEach(error => this.$errorValidadation.append(new Dom(`<div class="alert alert-warning mt-4" role="alert">${error}</div>`)));
  }

  onRendered() {
    this.$formLogin = this.$el.find('#form-login');
    this.$btnLogar = this.$el.find('#btn-confirmar');
    this.$errorValidadation = this.$el.find('#error-validadtion');
    this.$modal = this.$el.find('#modal-confirmacao');
    this.modal = new Modal(this.$modal[0], {});
    this.$modal.on('hidden.bs.modal', () => {
      localStorage.setItem('usuario', '');
      window.location.reload();
    });
  }

}

export default TrocaSenha;
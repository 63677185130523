import Component from 'ds-framework/core/Component';
import template from './verificar-usuario.html';
import formGetData from 'ds-framework/component/form/formGetData';
import api from 'ds-framework/core/api';
import validationStore from '../../store/validationStore';
import Dom from 'ds-framework/core/Dom';
import router from 'ds-framework/core/router';
import usuarioStore from '../../store/usuarioStore';

class VerificaUsuario extends Component {
  constructor() {
    super(template); //usuarioStore.onStateChange(() => this.validarUsuario())
  }

  showErrorEmpresa() {
    this.$el.html(`<div><i class="fas fa-info-circle fa-8x"></i></div>
            <div class="row justify-content-md-center mt-4"><h3>Nenhum acesso foi liberado para usuário informado, por favor entre em contato com administrador</h3></div>`);
  }

  validarUsuario() {
    const primeiroAcesso = usuarioStore.state.primeiroAcesso;
    const empresas = usuarioStore.state.empresas;

    if (primeiroAcesso) {
      router.navigate('trocar-senha');
    } else if (empresas.length > 0) {
      router.navigate('portal');
    } else {
      this.showErrorEmpresa();
    }
  }

  onRendered() {
    usuarioStore.verificarUsuario().then(() => this.validarUsuario());
  }

}

export default VerificaUsuario;
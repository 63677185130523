import Store from "ds-framework/core/Store";
import api from 'ds-framework/core/api';

class MobileStore extends Store {
  constructor() {
    super(MobileStore, {
      isMobile: false
    });
  }

}

const mobileStore = new MobileStore();
export default mobileStore;
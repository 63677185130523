import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dom from 'ds-framework/core/Dom';
import router from 'ds-framework/core/router';
import Login from './App/Login';
import Portal from './App/Portal';
import VerificaUsuario from './App/VerificaUsuario';
import TrocaSenha from './App/TrocaSenha';
import app from 'ds-framework/core/app';
import api from 'ds-framework/core/api';
import validationStore from './store/validationStore';
import usuarioStore from './store/usuarioStore';
import configuracaoStore from './store/configuracaoStore';
import mobileStore from './store/mobileStore';
import PrimeiroPasso from './App/PrimeiroPasso';
import Carregando from './App/Carregando/index.';
window.addEventListener('load', event => {
  api.baseUrl = process.env.API_BASE_URL;
  api.cors = true;
  let $el = new Dom('router-viewer');
  router.add([{
    path: 'login',
    component: Login
  }, {
    path: 'portal',
    component: Portal
  }, {
    path: 'verificar-usuario',
    component: VerificaUsuario
  }, {
    path: 'trocar-senha',
    component: TrocaSenha
  }, {
    path: 'primeiros-passos',
    component: PrimeiroPasso
  }, {
    path: 'carregando',
    component: Carregando
  }, {
    path: '404' //component: NotFound,

  }]);
  mobileStore.commit({
    isMobile: isMobile()
  });
  router.init($el);
  router.navigate('carregando');
  app.init($el);
  app.renderRegisteredComponent();
  usuarioStore.localToken();
  const usuario = usuarioStore.state.usuario;

  if (usuario.token) {
    usuarioStore.verifyToken().then(() => {
      configuracaoStore.getConfiguracao().then(() => {
        router.navigate('verificar-usuario');
      });
    }).catch(() => {
      configuracaoStore.getConfiguracao().then(() => {
        //router.navigate('verificar-usuario')
        router.navigate('login');
      });
    });
  } else {
    usuarioStore.getTokenCorporacao().then(() => {
      configuracaoStore.getConfiguracao().then(() => router.navigate('login'));
    });
  }
});
window.addEventListener('resize', event => mobileStore.commit({
  isMobile: isMobile()
}));

api.onError400 = function (data) {
  let erros = [];

  for (let prop in data.errors) {
    data.errors[prop].forEach(error => erros.push(error));
  }

  validationStore.commit({
    erros: erros
  });
};

api.getHeaderTokenAuthorization = function () {
  if (usuarioStore.state.usuario.token) {
    return {
      'Authorization': `Bearer ${usuarioStore.state.usuario.token}`
    };
  }
};

configuracaoStore.onStateChange(() => {
  const $head = new Dom(document.head);
  const configuracao = configuracaoStore.state.configuracao;

  if (configuracao) {
    const $style = new Dom('<style></style>');
    $style.html(`

        .bg-primary {
            background-color: ${configuracao.corPrincipal} !important;
        }

        `);
    $head.append($style);
  }
});

function isMobile() {
  let styles = getComputedStyle(document.querySelector('body'));
  let width = parseFloat(styles.width.replace('px', ''));
  return width < 600 ? true : false;
}
import Store from "ds-framework/core/Store";
import api from 'ds-framework/core/api';

class ConfiguracaoStore extends Store {
  constructor() {
    super(ConfiguracaoStore, {
      configuracao: {}
    });
  }

  async getConfiguracao() {
    const result = await api.get({
      url: `v1/portal-conveniado/configuracao`
    });
    this.commit({
      configuracao: result
    });
    return Promise.resolve();
  }

}

const configuracaoStore = new ConfiguracaoStore();
export default configuracaoStore;
import Component from 'ds-framework/core/Component';
import template from './primeiro-passo.html';
import router from 'ds-framework/core/router';

class PrimeiroPasso extends Component {
  constructor() {
    super(template);
    this.destroyOnRender = true;
  }

  show(id) {
    const contratos = ['tour-convenio', 'tour-form-search', 'tour-contratos'];
    const autenticar = ['tour-card-autenticar', 'tour-datanascimento', 'tour-autenticar-btn', 'tour-autenticar-btn-cancelar'];
    const tabela = ['tour-beneficio-tabela', 'tour-beneficio-descricao', 'tour-beneficio-btn-consulta', 'tour-beneficio-autorizado', 'tour-beneficio-observacao', 'tour-beneficio-btn-guia', 'tour-beneficio-btn-novo'];
    this.$divContratos.css('display', contratos.indexOf(id) === -1 ? 'none' : '');
    this.$divAutenticar.css('display', autenticar.indexOf(id) === -1 ? 'none' : '');
    this.$divTabela.css('display', tabela.indexOf(id) === -1 ? 'none' : '');
  }

  onRendered() {
    this.$divContratos = this.$el.find(".div-contratos");
    this.$divAutenticar = this.$el.find(".div-autenticar");
    this.$divTabela = this.$el.find(".div-tabela");
    const into = introJs().setOptions({
      exitOnEsc: false,
      exitOnOverlayClick: false,
      nextLabel: 'Próximo',
      prevLabel: 'Anterior',
      doneLabel: 'Finalizar',
      steps: [{
        title: 'Tour Guiado',
        intro: 'Siga o tour guiado de demonstração do Portal do Conveniado.'
      }, {
        element: document.querySelector('#tour-convenio'),
        intro: 'Descrição da empresa Conveniada'
      }, {
        element: document.querySelector('#tour-form-search'),
        intro: 'Campo de pesquisa do beneficiário'
      }, {
        element: document.querySelector('#tour-contratos'),
        intro: 'Beneficiários que foram encontrados'
      }, {
        element: document.querySelector('#tour-card-autenticar'),
        intro: 'Autenticação do beneficiário'
      }, {
        element: document.querySelector('#tour-datanascimento'),
        intro: 'Campo para informar a data de nascimento do beneficiário'
      }, {
        element: document.querySelector('#tour-autenticar-btn'),
        intro: 'Botão para confirmar autenticação'
      }, {
        element: document.querySelector('#tour-autenticar-btn-cancelar'),
        intro: 'Botão para cancelar autenticação'
      }, {
        element: document.querySelector('#tour-beneficio-tabela'),
        intro: 'Tabela de benefícios'
      }, {
        element: document.querySelector('#tour-beneficio-descricao'),
        intro: 'Descrição de benefício'
      }, {
        element: document.querySelector('#tour-beneficio-btn-consulta'),
        intro: 'Botão para validar o benefício'
      }, {
        element: document.querySelector('#tour-beneficio-autorizado'),
        intro: 'Icone de indicação, se benefício está valido para uso ou não'
      }, {
        element: document.querySelector('#tour-beneficio-observacao'),
        intro: 'Observação do porque o benefício não está valido para uso'
      }, {
        element: document.querySelector('#tour-beneficio-btn-guia'),
        intro: 'Botão para impressão da guia do benefício'
      }, {
        element: document.querySelector('#tour-beneficio-btn-novo'),
        intro: 'Botão para consultar novo beneficiário'
      }, {
        title: 'Parabéns!',
        element: document.querySelector('#link'),
        intro: 'Você chegou no final!'
      }]
    });
    into.onbeforechange(targetElement => {
      if (targetElement) this.show(targetElement.getAttribute('id'));
    });
    into.onexit(() => {
      router.navigate('portal');
    });
    into.oncomplete(() => {
      router.navigate('portal');
    });
    into.start();
  }

}

export default PrimeiroPasso;
import Component from "ds-framework/core/Component";
import template from './portal.html';
import IMask from 'imask';
import { onlyNumber } from 'ds-framework/helper/string';
import api from "ds-framework/core/api";
import Dom from "ds-framework/core/Dom";
import contratoStore from "../../store/contratoStore";
import Header from "../Header";
import usuarioStore from "../../store/usuarioStore";
import Autenticar from "./Autenticar";
import Beneficio from "./Beneficio";
import { Modal } from "bootstrap";
import ContratoInformacao from "./ContratoInformacao";

class Portal extends Component {
  constructor() {
    super(template);
    this.components.push({
      name: 'header',
      Component: Header
    });
    this.components.push({
      name: 'autenticar',
      Component: Autenticar
    });
    this.components.push({
      name: 'beneficio',
      Component: Beneficio
    });
    this.components.push({
      name: 'contrato-informacao',
      Component: ContratoInformacao
    });
    contratoStore.onStateChange(() => {
      this.listarContratos();
      this.mostrarNenhumContratoEncontrado();
      this.limparBusca();
    });
    usuarioStore.onStateChange(() => this.mostrarConvenio());
    this.timeoutId = 0;
    this.$listContratos = new Dom(`<div class="list-group mt-2"></div>`);
  }

  async searchContrato() {
    let search = this.$inputSearch.val();
    let cpf = search.replace(/[^0-9]+/g, '');
    let isCpf = cpf.length > 0 && cpf.length === 11;
    let isNome = search.split(' ').filter(text => text.length > 0).length > 1;
    let isContrato = cpf.length >= 6 && cpf.length <= 8;

    if (isCpf || !isCpf && isNome || isContrato) {
      this.$loading.css('display', '');
      const results = await api.get({
        url: 'v1/portal-conveniado/pesquisar-contrato',
        data: {
          search,
          isCpf,
          isNome,
          isContrato
        }
      });
      this.$loading.css('display', 'none');
      contratoStore.commit({
        contratos: results,
        contrato: {},
        encontrado: results.length > 0 ? true : false,
        carencias: []
      });
    } else {
      contratoStore.commit({
        contratos: [],
        contrato: {},
        encontrado: true,
        carencias: []
      });
    }

    return Promise.resolve();
  }

  listarContratos() {
    const contratos = contratoStore.state.contratos;
    const contrato = contratoStore.state.contrato;
    this.$listContratos.remove();
    this.$listContratos.empty();

    if (contrato.id) {
      return;
    }

    contratos.forEach(contrato => {
      const $item = new Dom(`
            <a href="#" class="list-group-item list-group-item-action"><div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">${contrato.nome}</h5>
                <small>${contrato.numero}</small>
            </div></a>`);
      $item.click(e => {
        e.preventDefault();

        if ([1, 9, 4, 6].indexOf(contrato.idSituacao) !== -1) {
          this.$modalLabelSituacao.html(contrato.numero);
          this.modal.show();
          return;
        }

        this.$inputSearch.val(contrato.nome);
        this.maskInputSearch.updateValue();
        this.$inputSearch.attr('disabled', true);
        contratoStore.commit({
          contrato
        });
        contratoStore.getInformacoesContrato(contrato.id);
      });
      this.$listContratos.append($item);
    });

    if (contratos.length > 0) {
      this.$listContratos.after(this.$inputSearch);
    }
  }

  mostrarNenhumContratoEncontrado() {
    const encontrado = contratoStore.state.encontrado;

    if (encontrado) {
      this.$divContratoNaoEncontrado.css('display', 'none');
    } else {
      this.$divContratoNaoEncontrado.css('display', '');
    }
  }

  mostrarConvenio() {
    const empresa = usuarioStore.state.empresa;

    if (empresa.descricao) {
      this.$convenio.html(empresa.descricao);
    }
  }

  limparBusca() {
    const limparContrato = contratoStore.state.limparContrato;

    if (limparContrato === true) {
      this.$inputSearch.removeAttr('disabled');
      this.$inputSearch.val('');
      this.maskInputSearch.updateValue();
      this.$inputSearch.focus();
      contratoStore.commit({
        limparContrato: false
      });
    }
  }

  initInputSearch() {
    this.maskInputSearch = IMask(this.$inputSearch[0], {
      mask: [{
        mask: '00000000'
      }, {
        mask: '000.000.000-00'
      }, {
        mask: /^[a-zA-Z\s]*$/
      }]
    });
    this.$inputSearch.on('keyup', e => {
      if (e.key && e.key.length == 1) {
        let value = this.$inputSearch.val() || '';
        let selection = e.target.selectionStart || 0;
        value = value.toLocaleUpperCase();
        this.$inputSearch.val(value);
        this.maskInputSearch.updateValue();
        e.target.selectionStart = selection;
        e.target.selectionEnd = selection;
      }

      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => this.searchContrato(), 700);
    }); // this.$inputSearch.on('keydown', (e) => {
    // })
  }

  onRendered() {
    this.$convenio = this.$el.find('#convenio');
    this.$inputSearch = this.$el.find('#input-search');
    this.$loading = this.$el.find('#loading');
    this.$divContratoNaoEncontrado = this.$el.find("#contrato-nao-encontrado");
    this.$btnNovo = this.$el.find('#btn-novo');
    this.$divSearch = this.$el.find('#div-search');
    this.$loading.css('display', 'none');
    this.$divContratoNaoEncontrado.css('display', 'none');
    this.$modalLabelSituacao = this.$el.find(`#modal-contrato`);
    this.$modalSitucaoContrato = this.$el.find('#modal-situacao-contrato');
    this.modal = new Modal(this.$modalSitucaoContrato[0], {});
    this.initInputSearch();
    this.mostrarConvenio();
  }

}

export default Portal;
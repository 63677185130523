import Component from 'ds-framework/core/Component';
import contratoStore from '../../store/contratoStore';
import template from './contrato-informacao.html';
import formSetData from 'ds-framework/component/form/formSetData';

class ContratoInformacao extends Component {
  constructor() {
    super(template);
    contratoStore.onStateChange(() => {
      this.exibirInformacoes();
    });
  }

  exibirInformacoes() {
    const {
      informacaoContrato
    } = contratoStore.state;

    if (informacaoContrato && informacaoContrato.plano) {
      this.$el.css('display', '');
      formSetData(this.$el, informacaoContrato);
    } else {
      this.$el.css('display', 'none');
      formSetData(this.$el, {
        cobertura: '',
        dataContrato: '',
        plano: '',
        situacao: '',
        titular: ''
      });
    }
  }

  onRendered() {
    this.exibirInformacoes();
  }

}

export default ContratoInformacao;